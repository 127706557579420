/*! csstools-normalize v9.0.1 | github.com/csstools/normalize.css */

/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
    line-height: 1.15;
    /* 1 */
    -ms-text-size-adjust: 100%;
    /* 2 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
    display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
    background-color: transparent;
}

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */

abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
    font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
    font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
    display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */

img {
    border-style: none;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
    overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin in Safari.
 */

button,
input,
select {
    margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */

button {
    overflow: visible;
    /* 1 */
    text-transform: none;
    /* 2 */
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge and IE.
 */

input {
    overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
    box-sizing: border-box;
    /* 1 */
    color: inherit;
    /* 2 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    white-space: normal;
    /* 1 */
}

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
    display: inline-block;
    /* 1 */
    vertical-align: baseline;
    /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
    text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
    margin: 0;
    /* 1 */
    overflow: auto;
    /* 2 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge and IE.
 */

details {
    display: block;
}

/*
 * Add the correct styles in Edge, IE, and Safari.
 */

dialog {
    background-color: white;
    border: solid;
    color: black;
    display: block;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    left: 0;
    margin: auto;
    padding: 1em;
    position: absolute;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}

dialog:not([open]) {
    display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
    display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
    display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
    display: none;
}

/* User interaction
 * ========================================================================== */

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
    display: none;
}

@import url('https://fonts.googleapis.com/css?family=Work+Sans');

* {
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: "Nunito", serif;
    // background: rgb(244, 246, 248);
    background: #FAFBFE;
}

$scollbar-hover: #dddddd !default;
$animation-duration: 0.2s !default;

$scrollbar-thumb: darken($scollbar-hover, 3%);
$scrollbar-thumb-hover: darken($scollbar-hover, 10%);

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-hover;
}

$brand-primary: #3e214f;

.app {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .company {
        flex: 1;
        // max-width: 400px;
        background: $brand-primary;
        justify-content: center;
        align-items: center;
        display: flex;

        .content {
            padding: 100px;

            margin: 0 auto;

            text-align: center;

            svg {
                width: 140px;
            }

            a {
                color: white;
                text-decoration: none;
                font-size: 1.4rem;
                margin-top: 20px;
                display: block;
            }

            .title {
                font-size: 3.8rem;
                color: white;
            }

            .subtitle {
                font-size: 1.6rem;

                color: white;
                opacity: 0.7;
            }

            .about {

                img {
                    border-radius: 50%;
                    width: 100px;
                    display: none;
                    height: 100px;
                    background-size: contain;
                }
            }


        }
    }

    .work {
        flex: 1;

        .content {
            overflow: auto;
            max-height: 100vh;
            padding: 100;

            h1 {
                margin-bottom: 10px;
                text-align: center;
                display: inline-block;
            }

            .card-body {
                padding: 20px;

            }

            .columns {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;

                .card+.card {
                    margin-left: 20px;
                }
            }

            .card {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
                background: white;
                margin-bottom: 1em;
                // padding: 10px;
                box-shadow: 0 1px 0 0 rgba(44, 47, 61, .04), 0 8px 24px 0 rgba(66, 71, 94, .07);

                &:hover {
                    .overlay {
                        display: flex;
                    }
                }

                .overlay {
                    position: absolute;
                    background: #e6757a; //rgba(0, 0, 0, 0.4);
                    display: none;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    color: white;
                    text-align: center;

                    h1 {
                        color: white;
                    }

                    p {
                        font-size: 1.5rem;
                    }
                }

                img {
                    max-width: 100%;

                }
            }
        }
    }


    footer {
        padding: 40px;
        text-align: center;

        color: black;
        opacity: 0.5;
    }



}



@media (max-width: 1224px) {

    .app {
        flex-direction: column;
        overflow: auto;

        .work {
            .content {
                padding: 20px;
                max-height: inherit;

                .columns {
                    flex-direction: column;

                    .overlay {
                        p {
                            font-size: 0.9rem;
                        }

                    }

                    .card+.card {
                        margin-left: 0px;
                    }
                }
            }
        }


    }
}